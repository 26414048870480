import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { homeContent } from '../../content/index';
import ContentImageTile from '../ContentImageTile';
import Button from '../Button';

function Join() {
  const { header, cta, cards } = homeContent.join;

  return (
    <div data-testid="joinContainer" className="mx-auto">
      <h2
        className="text-center text-base font-bold font-sans md:text-xl lg:text-2xl tracking-tight"
        data-testid="joinTitle"
      >
        {header}
      </h2>
      <div
        className={classNames(
          'grid grid-cols-2 mt-6 gap-x-2.5 gap-y-5 max-w-1400px',
          'md:grid-cols-3 md:gap-y-7 md:mt-9 md:gap-x-7',
          'lg:grid-cols-5 lg:gap-x-5 mx-auto',
        )}
        data-testid="joinGrid"
      >
        {cards.map((item, index: Number) => {
          return (
            <Link
              to={item.link}
              className={classNames('justify-center flex flex-col', {
                'lg:hidden': index === 5,
              })}
              key={item.title}
              data-testid="joinCard"
            >
              <ContentImageTile
                imagePath={`../../home/join/${item.image.file}`}
                className="shadow-md"
                imageClassName="object-contain"
                isAnimated
                alt={item.image.alt}
              />
              <div className="text-10px xs:text-sm xl:text-lg md:font-medium uppercase tracking-tighter mt-2.5 text-center">
                {item.title}
              </div>
            </Link>
          );
        })}
      </div>
      <Button
        text={cta.desktop}
        className="md:block hidden uppercase mx-auto mt-7"
        to="/join"
        variant="transparent"
      />
      <Button
        text={cta.mobile}
        className="md:hidden block uppercase mx-auto mt-7"
        to="/join"
        variant="transparent"
      />
    </div>
  );
}

export default Join;
