import * as React from 'react';
import { homeContent } from '../../content/index';
import ServicesCard from './ServicesCard';
import Button from '../Button';

const Services = () => {
  const { header, cta, cards } = homeContent.services;

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 lg:space-y-0">
      <div>
        <div className="lg:pt-9 lg:sticky lg:top-9 mb-6 md:mb-10">
          <div
            data-testid="services-header"
            className="font-semibold text-lg md:text-xl md:mb-7 md:font-bold lg:text-24px lg:pr-5 md:w-8/12 xl:w-10/12"
          >
            {header}
          </div>
          <div
            data-testid="services-cta"
            className="md:block hidden lg:font-black"
          >
            <Button
              className="lg:text-base md:text-sm uppercase"
              text={cta.desktop}
              variant="transparent"
              to="/services"
            />
          </div>
        </div>
      </div>
      <div
        data-testid="services-cards-container"
        className="place-items-center lg:place-content-center xl:place-items-end grid gap-y-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2  grid-cols-1 md:mb-0 mb-7 md:gap-x-4 md:gap-y-8 xl:pt-6"
      >
        {cards.map(card => (
          <ServicesCard
            isAnimated
            key={card.title}
            title={card.title}
            file={card.image.file}
            alt={card.image.alt}
            tags={card.tags}
            description={card.description}
            link={card.link}
          />
        ))}
      </div>
      <div className="mt-1 flex justify-center md:hidden">
        <Button
          text={cta.mobile}
          className="uppercase"
          variant="transparent"
          to="/services"
        />
      </div>
    </div>
  );
};
export default Services;
